<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria/cursos'
                "
                @click.prevent="$router.push('/plataforma/' +
                  $route.params.id_plataforma +
                  '/secretaria/cursos')"
              >Cursos</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Curso - {{ fastSecretariaCurso.nome_curso }}</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Curso
                  <span>{{ fastSecretariaCurso.nome_curso }}</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-4">
                  <transition name="slide-fade">
                    <div
                      v-if="fastSecretariaCurso.id_secretaria_curso"
                      class="card shadow mb-4"
                    >
                      <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">
                          Detalhes do curso {{ fastSecretariaCurso.nome_curso }}
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-6">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12">
                                <p class="m-0">
                                  Descrição:
                                  <span class="text-info">{{
                                    fastSecretariaCurso.detalhe_curso
                                  }}</span>
                                </p>
                                <p class="m-0">
                                  Publicado:
                                  <span
                                    v-if="fastSecretariaCurso.publicado"
                                    class="text-success"
                                  >Sim</span>
                                  <span
                                    v-else
                                    class="text-info"
                                  >Não</span>
                                </p>
                                <p class="m-0">
                                  Carga horária:
                                  <span class="text-info">{{
                                    fastSecretariaCurso.carga_horaria
                                  }}</span>
                                </p>                              
                                <p
                                  v-if="fastSecretariaCurso.creditos_total"
                                  class="m-0"
                                >                                
                                  Créditos:
                                  <span class="text-info">{{
                                    fastSecretariaCurso.creditos_total
                                  }}</span>
                                </p>
                              </div>
                              <div
                                v-if="fastSecretariaCurso.logo_curso"
                                class="col-sm-12 col-md-12 col-lg-12 mt-2"
                              >
                                <img
                                  :src="ajustaLinkApiAntiga(fastSecretariaCurso.logo_curso)"
                                  class="img-responsive"
                                  style="max-width: 300px"
                                >                              
                              </div>
                            </div>
                          </div>
                          <div class="col-6 d-flex flex-column ">
                            <div class="my-3 text-right">
                              <button v-if="fastFix.consultarAtividades" class="btn btn-primary" @click="getAtividadesFixToussaint">
                                <small>
                                  Verificar erros em atividades
                                </small>
                              </button>
                              <button v-else-if="fastFix.loadingBuscaAtividades" class="btn btn-primary">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Carregando...
                              </button>
                              <button v-else class="btn btn-primary" @click="exibeModalCorrigirAtividades">
                                <small>
                                  Corrigir atividades ({{ fastFix.atividades.ids_atividade.length }})
                                </small>
                              </button>
                            </div>
                            <div class="my-3 text-right">
                              <button v-if="fastFix.consultarQuestoes" class="btn btn-primary" @click="getQuestoesFixToussaint">
                                <small>
                                  Verificar erros em questões
                                </small>
                              </button>
                              <button v-else-if="fastFix.loadingBuscaQuestoes" class="btn btn-primary">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Carregando...
                              </button>
                              <button v-else class="btn btn-primary" @click="exibeModalCorrigirQuestoes">
                                <small>
                                  Corrigir questões ({{ fastFix.questoes.ids_questao.length }})
                                </small>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de módulos
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            v-if="!fastCursoPossuiTurmas"
                            class="btn btn-sm btn-primary"
                            @click.prevent="showModal('modalCriarPrograma')"
                          >
                            <small>+ Adicionar módulo</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Sequência</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold">Módulo</small>
                                </th> 
                                <th>
                                  <small class="font-weight-bold">Créditos</small>
                                </th> 
                                <th>
                                  <small class="font-weight-bold">Pré-requisito</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold">Preço</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold">Avaliações</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSecretariaCurso.programasLoading">
                              <tr>
                                <td
                                  colspan="7"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastSecretariaCurso.programas.length">
                              <tr
                                v-for="(programa, index) in fastSecretariaCurso.programas"
                                :key="index"
                              >
                                <td class="align-middle">
                                  <input
                                    v-model="programa.sequencia"
                                    style="width: 70px"
                                    type="number"
                                    min="1"
                                    class="form-control form-control-sm"
                                    @change="corrigeSequenciaPrograma(programa)"
                                  >
                                </td>
                                <td class="align-middle">
                                  <small>{{ programa.sigla_programa }}</small>
                                </td>  
                                <td class="align-middle">
                                  <small v-if="programa.creditos">{{ programa.creditos }}</small>
                                  <small
                                    v-else
                                    class="text-danger"
                                  >Nenhum</small>
                                </td>  
                                <td class="align-middle">
                                  <div
                                    v-for="programa2 in fastSecretariaCurso.programas"
                                    :key="programa2.id_secretaria_curso_programa"
                                  >
                                    <small v-if="programa2.id_secretaria_curso_programa == programa.id_pai">{{ programa2.sigla_programa }}</small>
                                  </div>
                                </td> 
                                <td class="align-middle">
                                  <small v-if="programa.preco_modulo">R$ {{ formataPreco(programa.preco_modulo) }}</small>
                                  <small
                                    v-else
                                    class="text-danger"
                                  >Indefinido</small>
                                </td>    
                                <td class="align-middle">
                                  <span
                                    v-if="programa.total_avaliacoes"
                                    class="btn btn-sm btn-success pt-0 pb-0"
                                  >
                                    <small>Sim</small>
                                  </span>
                                  <span
                                    v-else
                                    class="btn btn-sm btn-danger pt-0 pb-0"
                                  >
                                    <small>Não</small>
                                  </span>
                                </td>                         
                                <td class="text-center align-middle">
                                  <a
                                    :href="
                                      '/plataforma/' +
                                        $route.params.id_plataforma +
                                        '/secretaria/curso/' +
                                        $route.params.id_secretaria_curso +
                                        '/programa/' +
                                        programa.id_secretaria_curso_programa
                                    "
                                    class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                    @click.prevent="$router.push('/plataforma/' +
                                      $route.params.id_plataforma +
                                      '/secretaria/curso/' +
                                      $route.params.id_secretaria_curso +
                                      '/programa/' +
                                      programa.id_secretaria_curso_programa)"
                                  >
                                    <small>Matriz curricular
                                      <span class="badge badge-light">{{
                                        programa.total_cursos
                                      }}</span></small>
                                  </a>
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarPrograma(programa)"
                                  >
                                    <small>Editar</small>
                                  </button>                                  
                                  <button  
                                    v-if="!fastCursoPossuiTurmas"                                  
                                    class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                    @click="exibeModalExcluirPrograma(programa)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="7"
                                  class="text-center"
                                >
                                  Nenhum módulo cadastrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            v-if="fastProgramaEditar.alteracao_pendente"
                            class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                          >
                            <button
                              class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                              @click.prevent="atualizaSequenciaPrograma()"
                            >
                              <small>
                                <i
                                  class="fa fa-floppy-o"
                                  aria-hidden="true"
                                />
                                &nbsp;Salvar alterações
                              </small>
                            </button>
                            <button
                              class="btn btn-secondary btn-sm mt-2 ml-2"
                              @click.prevent="getFastProgramas()"
                            >
                              <small>
                                <i
                                  class="fa fa-ban"
                                  aria-hidden="true"
                                />
                                &nbsp;Cancelar
                              </small>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria/cursos'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/cursos')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarPrograma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo módulo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarPrograma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Sigla do módulo</label>
              <input
                v-model="fastProgramaNovo.sigla_programa"
                class="form-control"
                type="text"
              >
            </div>          
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Créditos</label>
              <input
                v-model="fastProgramaNovo.creditos"
                class="form-control"
                type="number"
              >
            </div>   
            <div
              v-if="fastSecretariaCurso.programas.length > 1"
              class="col-sm-12 col-md-12 col-lg-4 mb-2"
            >
              <label>Pré-requisito</label>
              <select
                v-model="fastProgramaNovo.id_pai"
                class="form-control"
              >
                <option
                  v-for="programa in fastSecretariaCurso.programas"
                  :key="programa.id_secretaria_curso_programa"
                  :value="programa.id_secretaria_curso_programa"
                >
                  {{ programa.sigla_programa }}
                </option>
              </select>
            </div>     
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Preço do módulo</label>
              <input
                v-model="fastProgramaNovo.preco"
                type="number"
                step=".01"
                class="form-control"
              >
            </div>  
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Gera certificado</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastProgramaNovo.gera_certificado"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarPrograma()"
                >
                  Criar módulo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarPrograma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar módulo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarPrograma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Sigla do módulo</label>
              <input
                v-model="fastProgramaEditar.sigla_programa"
                class="form-control"
                type="text"
              >
            </div>  
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Créditos</label>
              <input
                v-model="fastProgramaEditar.creditos"
                class="form-control"
                type="number"
              >
            </div>   
            <div
              v-if="fastSecretariaCurso.programas.length > 1"
              class="col-sm-12 col-md-12 col-lg-4 mb-2"
            >
              <label>Pré-requisito</label>
              <select
                v-model="fastProgramaEditar.id_pai"
                class="form-control"
              >
                <option
                  v-for="programa in fastSecretariaCurso.programas"
                  :key="programa.id_secretaria_curso_programa"
                  :value="programa.id_secretaria_curso_programa"
                >
                  {{ programa.sigla_programa }}
                </option>
              </select>
            </div>    
            <div class="col-sm-12 col-md-12 col-lg-4 mb-4">
              <label>Preço do módulo</label>
              <input
                v-model="fastProgramaEditar.preco_modulo"
                type="number"
                step=".01"
                class="form-control"
              >
            </div>  
            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
              <label>Gera certificado</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastProgramaEditar.gera_certificado_modulo"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>      
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarPrograma()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirPrograma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir programa?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirPrograma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="excluirPrograma()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCorrigirAtividades"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-6 col-md-6 col-lg-6 pl-4 pr-4">
          <h4>Corrigir Atividades</h4>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCorrigirAtividades')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 px-4">
          <span class="text-secondary">Identificados {{  fastFix.atividades.ids_atividade.length }} pontos de correção.</span> <span v-if="this.fastFix.atividadesResolvidas.length">Corrigidos: {{fastFix.atividadesResolvidas.length}}</span>
        </div>
        <div v-if="fastFix.executandoCorrigirAtividades" class="col-12 d-flex justify-content-center my-2">
          <div class="spinner-border text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center my-3">
          <div class="progress w-75 p-0">
            <div class="progress-bar progress-bar-striped progress-bar-animated" 
                 role="progressbar" aria-valuenow="100" aria-valuemin="0" 
                 aria-valuemax="100" :style="fastFix.barraProgressoAtividadesPercentual">{{ fastFix.barraProgressoAtividades }}%</div>
          </div>        
        </div>
        <div class="col-12 my-4 text-center">
          <button class="btn btn-danger" v-if="fastFix.executandoCorrigirAtividades" @click="fastFix.executandoCorrigirAtividades = false">
            Interromper processo
          </button>
          <button v-else class="btn btn-success" @click="fastFix.executandoCorrigirAtividades = true, funcCorrigeAtividades()">
            Executar correção de atividades
          </button>
        </div>
        <div class="col-12 px-4 my-3">
          <h5 class="text-danger">Erros identificados ({{ fastFix.atividadesRetornoErro.length }})</h5>
          <div class="d-flex flex-column log-errors-modal border">
            <span v-for="log in fastFix.atividadesRetornoErro" :key="log">-> {{ log }}</span>
            <span v-if="!fastFix.atividadesRetornoErro.length" class="text-secondary">Não há erros para exibir</span>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCorrigirQuestoes"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthLg"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-6 col-md-6 col-lg-6 pl-4 pr-4">
          <h4>Corrigir Questões</h4>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCorrigirQuestoes')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 px-4">
          <span class="text-secondary">Identificados {{  fastFix.questoes.ids_questao.length }} pontos de correção.</span> <span v-if="this.fastFix.questoesResolvidas.length">Corrigidos: {{fastFix.questoesResolvidas.length}}</span>
        </div>
        <div v-if="fastFix.executandoCorrigirQuestoes" class="col-12 d-flex justify-content-center my-2">
          <div class="spinner-border text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center my-3">
          <div class="progress w-75 p-0">
            <div class="progress-bar progress-bar-striped progress-bar-animated" 
                  role="progressbar" aria-valuenow="100" aria-valuemin="0" 
                  aria-valuemax="100" :style="fastFix.barraProgressoQuestoesPercentual">{{ fastFix.barraProgressoQuestoes }}%</div>
          </div>        
        </div>
        <div class="col-12 my-4 text-center">
          <button class="btn btn-danger" v-if="fastFix.executandoCorrigirQuestoes" @click="fastFix.executandoCorrigirQuestoes = false">
            Interromper processo
          </button>
          <button v-else class="btn btn-success" @click="fastFix.executandoCorrigirQuestoes = true, funcCorrigeQuestoes()">
            Executar correção de questões
          </button>
        </div>
        <div class="col-12 px-4 my-3">
          <h5 class="text-danger">Erros identificados ({{ fastFix.questoesRetornoErro.length }})</h5>
          <div class="d-flex flex-column log-errors-modal border">
            <span v-for="log in fastFix.questoesRetornoErro" :key="log">-> {{ log }}</span>
            <span v-if="!fastFix.questoesRetornoErro.length" class="text-secondary">Não há erros para exibir</span>
          </div>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoSecretariaCurso",
  components: {},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastSecretariaCurso: {
        id_secretaria_curso: 0,
        nome_curso: "...",
	      detalhe_curso: "",
	      publicado: "",
	      carga_horaria: "",
	      logo_curso: "",
	      id_plataforma: 0,
        creditos_total: "",
        nivel: "",
        preco_curso: "",
        venda_modulo: "",
        venda_unidade_curricular: "",
        gera_certificado_curso: "",
        id_base_curricular: 0,
        loja_virtual: false,
        programasLoading: true,
        programas: [],
      },
      fastProgramaNovo: {
        id_secretaria_curso_programa: 0,
        id_secretaria_curso: 0,
        sigla_programa: "",
        sequencia: 0,
        id_pai: 0,
        creditos: 0, 
        preco_modulo: 0.00,       
        preco: 0.00,
        gera_certificado_modulo: false,
        id_base_curricular: 0,
        gera_certificado: false
      },
      fastProgramaEditar: {
        id_secretaria_curso_programa: 0,
        id_secretaria_curso: 0,
        sigla_programa: "",
        sequencia: 0,
        id_pai: 0,
        creditos: 0,
        preco_modulo: 0.00,
        preco: 0.00,
        gera_certificado_modulo: false,
        gera_certificado: false,
        id_base_curricular: 0,
        alteracao_pendente: false
      },
      fastCursoPossuiTurmas: 1,
      fastFix: {
        atividades: {
          log: [],
          ids_atividade: [],
        },
        questoes: {
          log: [],
          ids_questao: [],
        },
        loadingBuscaAtividades: true,
        barraProgressoAtividades: 0,
        barraProgressoAtividadesPercentual: 0,
        atividadesResolvidas: [],
        atividadesRetornoErro: [],
        executandoCorrigirAtividades: false,
        atividadesAnalisadas: 0,
        consultarAtividades: true,
        loadingBuscaQuestoes: true,
        barraProgressoQuestoes: 0,
        barraProgressoQuestoesPercentual: 0,
        questoesResolvidas: [],
        questoesRetornoErro: [],
        executandoCorrigirQuestoes: false,
        questoesAnalisadas: 0,
        consultarQuestoes: true,
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_secretaria_curso) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getFastSecretariaCursoProgramas();
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    // Cursos
    async getFastSecretariaCursoProgramas() {
      this.getFastSecretariaCurso().then(async (curso) => {
        this.fastSecretariaCurso = {
          id_secretaria_curso: curso.id_secretaria_curso,
          nome_curso: curso.nome_curso,
          detalhe_curso: curso.detalhe_curso,
          publicado: curso.publicado,
          carga_horaria: curso.carga_horaria,
          logo_curso: curso.logo_curso,
          id_plataforma: curso.id_plataforma,
          creditos_total: curso.creditos_total,
          nivel: curso.nivel,
          preco_curso: curso.preco,
          venda_modulo: curso.venda_modulo,
          venda_unidade_curricular: curso.venda_unidade_curricular,
          gera_certificado_curso: curso.gera_certificado,
          id_base_curricular: curso.id_base_curricular,
          programasLoading: true,
          programas: [],
        }       
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_curso_programa/lista?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_secretaria_curso=" +
              this.$route.params.id_secretaria_curso,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = Array.from(json);
          console.log("getFastSecretariaProgramas", obj)
          if (obj.length) {      
            this.fastSecretariaCurso.programas = obj;
          }
          this.$store.state.fastCarregando = false;
          this.fastSecretariaCurso.programasLoading = false

        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false;
          this.fastSecretariaCurso.programasLoading = false
        }
      })
      .then(() => {
        this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=0")
          .then(json => {    
              let turmasCurso = 0    
              let obj = Array.from(json);  
              if (obj.length)  turmasCurso = obj.filter(e =>  e.id_secretaria_curso === this.fastSecretariaCurso.id_secretaria_curso) 
              this.fastCursoPossuiTurmas = turmasCurso.length              
          })
      })
    },
    async criarPrograma() {
      let erros = [];
      if (!this.fastProgramaNovo.sigla_programa) erros.push("O nome do programa é obrigatório");
      this.fastProgramaNovo.id_secretaria_curso = this.$route.params.id_secretaria_curso;
      this.fastProgramaNovo.id_pai = this.fastProgramaNovo.id_pai ? this.fastProgramaNovo.id_pai : 0;

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_secretaria_curso_programa/insere",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastProgramaNovo))
          );

          let json = await resp.json();
          console.log("criarPrograma", json);
          let obj = Array.from(json);
          this.$store.state.fastCarregando = false;
          this.getFastSecretariaCursoProgramas();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Programa criado com sucesso",
          });
          this.fastProgramaNovo = {
            id_secretaria_curso_programa: 0,
            id_secretaria_curso: 0,
            sigla_programa: "",
            sequencia: 0,
            id_pai: 0,
            creditos: 0,        
            preco_modulo: 0.00,
            gera_certificado_modulo: false
          };
          this.hideModal("modalCriarPrograma");
        } catch (e) {
          this.$store.state.fastCarregando = false;
          console.log("Erro", e);
        }
      }
    },
    exibeModalEditarPrograma(programa) {
      this.fastProgramaEditar = programa;

      this.showModal("modalEditarPrograma");
    },
    async editarPrograma() {
      let erros = [];
      if (!this.fastProgramaEditar.sigla_programa) erros.push("O nome do programa é obrigatório");
      this.fastProgramaEditar.id_secretaria_curso = this.$route.params.id_secretaria_curso;
      this.fastProgramaEditar.id_pai = this.fastProgramaEditar.id_pai ? this.fastProgramaEditar.id_pai : 0;
      this.fastProgramaEditar.creditos = this.fastProgramaEditar.creditos ? this.fastProgramaEditar.creditos : 0;

      this.fastProgramaEditar.preco = this.fastProgramaEditar.preco_modulo;
      this.fastProgramaEditar.gera_certificado = this.fastProgramaEditar.gera_certificado_modulo;
      
      console.log("this.fastProgramaEditar", this.fastProgramaEditar)
      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_secretaria_curso_programa/atualiza",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastProgramaEditar))
          );
          let json = await resp.json();
          console.log("editarPrograma", json);
          let obj = Array.from(json);
          this.$store.state.fastCarregando = false;
          this.getFastSecretariaCursoProgramas();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Programa editado com sucesso",
          });
          this.fastProgramaEditar = {
            id_secretaria_curso_programa: 0,
            id_secretaria_curso: 0,
            sigla_programa: "",
            sequencia: "",
            id_pai: "",
            creditos: "",        
            preco_modulo: "",
            gera_certificado_modulo: "",
          };
          this.hideModal("modalEditarPrograma");
        } catch (e) {
          this.$store.state.fastCarregando = false;
          console.log("Erro", e);
        }
      }
    },
    corrigeSequenciaPrograma(curso) {
      this.fastProgramaEditar.alteracao_pendente = true;
      this.fastSecretariaCurso.programas.sort(this.reornedarArrayObjetos("sequencia"));
    },
    atualizaSequenciaPrograma() {
      this.$store.state.fastCarregando = true;
      this.fastSecretariaCurso.programas.forEach((e, index) => {
        e.sequencia = index + 1;
        setTimeout(() => {
          this.salvaAtualizaSequenciaPrograma(e, index);
        }, 2000);
      });
    },
    async salvaAtualizaSequenciaPrograma(e, index) {
      const fast_secretaria_curso_programa = {
        id_secretaria_curso_programa: e.id_secretaria_curso_programa,        
        sequencia: e.sequencia,
      };
      console.log("fast_secretaria_curso_programa", fast_secretaria_curso_programa);
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_curso_programa/atualiza",
          this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_curso_programa))
        );

        let json = await resp.json();
        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        this.getFastSecretariaCursoProgramas();
        if (this.fastSecretariaCurso.programas.length == index + 1) {
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Sequência atualizada com sucesso",
          });
          this.$store.state.fastCarregando = false;
          this.fastProgramaEditar.alteracao_pendente = false;
        }
        this.fastProgramaEditar = {
          id_secretaria_curso_programa: 0,
            id_secretaria_curso: 0,
            sigla_programa: "",
            sequencia: "",
            id_pai: "",
            creditos: "",
        };
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    exibeModalExcluirPrograma(programa) {
      this.fastProgramaEditar = programa;
      this.showModal("modalExcluirPrograma");
    },
    async excluirPrograma() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_curso_programa/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(this.fastProgramaEditar))
        );
        let json = await resp.json();
        console.log("excluirPrograma", json);
        let obj = Array.from(json);
        this.getFastSecretariaCursoProgramas();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Programa excluído com sucesso",
        });
        this.hideModal("modalExcluirPrograma");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastSecretariaCurso() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_curso/lista?id_plataforma=" +
              this.$route.params.id_plataforma +
              "&id_secretaria_curso=" +
              this.$route.params.id_secretaria_curso,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            resolve(json[0]);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    async getAtividadesFixToussaint(){
      this.fastFix.consultarAtividades = false
      this.fastFix.loadingBuscaAtividades = true;
      try {
        this.promiseGetFastApi(
          "api/fast_secretaria_curso/lista_fix_toussaint_atividades",
          `id_plataforma=${this.$route.params.id_plataforma}&id_secretaria_curso=${this.$route.params.id_secretaria_curso}`
        ).then((res) => {
          if (res.success === false || res.error) {
            console.error(`${res.error ? res.error : ''} ${res.description ? res.description : ''}`);
            this.exibeToasty(`${res.error ? res.error : ''} ${res.description ? res.description : ''}`, "error");
          } else {
            this.fastFix.atividades = res
          }
        }).then(e => {
          this.fastFix.loadingBuscaAtividades = false;
          // console.log(e)
        })
      } catch (error) {
        console.error(error);
        this.exibeToasty("Ocorreu um erro", "error");
        this.fastFix.loadingBuscaAtividades = false;
      }
    },
    async getQuestoesFixToussaint(){
      this.fastFix.consultarQuestoes = false
      this.fastFix.loadingBuscaQuestoes = true;
      try {
        this.promiseGetFastApi(
          "api/fast_secretaria_curso/lista_fix_toussaint_questoes",
          `id_plataforma=${this.$route.params.id_plataforma}&id_secretaria_curso=${this.$route.params.id_secretaria_curso}`
        ).then((res) => {
          if (res.success === false || res.error) {
            console.error(`${res.error ? res.error : ''} ${res.description ? res.description : ''}`);
            this.exibeToasty(`${res.error ? res.error : ''} ${res.description ? res.description : ''}`, "error");
          } else {
            this.fastFix.questoes = res
          }
        }).then(e => {
          this.fastFix.loadingBuscaQuestoes = false;
          // console.log(e)
        })
      } catch (error) {
        console.error(error);
        this.exibeToasty("Ocorreu um erro", "error");
        this.fastFix.loadingBuscaQuestoes = false;
      }
    },
    async funcCorrigeAtividades(){
      for (const e of this.fastFix.atividades.ids_atividade) {
        if (this.fastFix.executandoCorrigirAtividades === false) return
        if (this.fastFix.executandoCorrigirAtividades === true) {
          try {
            let response = await fetch(settings.endApiFastEad + 
            'api/fast_secretaria_curso/fix_toussaint_atividade' + 
            `?id_plataforma=${this.$route.params.id_plataforma}&id_atividade=${e}`, 
            this.fastAjaxOptions("POST"))
            
            let res = await response.json();
            
            if (res.success === true) {
              this.fastFix.atividadesResolvidas.push(e)
            } else if (res.success === false){
              this.fastFix.atividadesRetornoErro.push(`Atvidade ${e}: ${res.error} ${res.description ? res.description : ""}`)
            }
            this.fastFix.atividadesAnalisadas++
            this.barraDeProgressoCorrigirAtividades()
            if (this.fastFix.atividadesAnalisadas >= this.fastFix.atividades.ids_atividade.length) this.fastFix.executandoCorrigirAtividades = false
          } catch (error) {
            console.error(error);
            this.exibeToasty("Ocorreu um erro", "error")
          }
        }
      }
    },
    exibeModalCorrigirAtividades(){
      if (this.fastFix.atividades.ids_atividade.length) {
        this.showModal('modalCorrigirAtividades')
      } else {
        this.exibeToasty("Não há pontos de correção", "info")
      }
    },
    barraDeProgressoCorrigirAtividades(){
      this.fastFix.barraProgressoAtividades = Math.ceil(((this.fastFix.atividadesAnalisadas * 100) / this.fastFix.atividades.ids_atividade.length))
      this.fastFix.barraProgressoAtividadesPercentual = {width: `${this.fastFix.barraProgressoAtividades}%`}
    },
    async funcCorrigeQuestoes(){
      for (const e of this.fastFix.questoes.ids_questao) {
        if (this.fastFix.executandoCorrigirQuestoes === false) return
        if (this.fastFix.executandoCorrigirQuestoes === true) {
          try {
            let response = await fetch(settings.endApiFastEad + 
            'api/fast_secretaria_curso/fix_toussaint_questao' + 
            `?id_plataforma=${this.$route.params.id_plataforma}&id_questao=${e}`, 
            this.fastAjaxOptions("POST"))
            
            let res = await response.json();
            
            if (res.success === true) {
              this.fastFix.questoesResolvidas.push(e)
            } else if (res.success === false){
              this.fastFix.questoesRetornoErro.push(`Atvidade ${e}: ${res.error} ${res.description ? res.description : ""}`)
            }
            this.fastFix.questoesAnalisadas++
            this.barraDeProgressoCorrigirQuestoes()
            if (this.fastFix.questoesAnalisadas >= this.fastFix.questoes.ids_questao.length) this.fastFix.executandoCorrigirQuestoes = false
          } catch (error) {
            console.error(error);
            this.exibeToasty("Ocorreu um erro", "error")
          }
        }
      }
    },
    exibeModalCorrigirQuestoes(){
      if (this.fastFix.questoes.ids_questao.length) {
        this.showModal('modalCorrigirQuestoes')
      } else {
        this.exibeToasty("Não há pontos de correção", "info")
      }
    },
    barraDeProgressoCorrigirQuestoes(){
      this.fastFix.barraProgressoQuestoes = Math.ceil(((this.fastFix.questoesAnalisadas * 100) / this.fastFix.questoes.ids_questao.length))
      this.fastFix.barraProgressoQuestoesPercentual = {width: `${this.fastFix.barraProgressoQuestoes}%`}
    },
  },
};
</script>

<style scope>

.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

.log-modal, .log-errors-modal{
  overflow-y: scroll;
}
.log-modal{
  height: 10em;
}
.log-errors-modal{
  height: 5em;
}
</style>
